.main-avatar {
    max-width: 200px;
    border: 2px solid rgba(214, 214, 214, 0.562);
    border-radius: 50%;
    float: left;
    margin-right: 1rem;
}

.link {
    color: #333 ;
    text-decoration: none;
}

a :hover {

    text-decoration: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}

.ml {
    margin-left: 0.5rem;
}